<template>
  <base-section id="k-d-a-team-structure-card" space="30">
    <v-container class="px-6 px-sm-7 px-lg-0">
      <v-row :class="`component-wrapper ${isEvenNumber ? 'row-reverse' : 'row-wrapper'}`">
        <v-col class="component-image">
          <img :src="imgAvatar" alt="avatar-image" class="avatar-image" />
        </v-col>
        <v-col class="component-title-description p-10" cols="12" md="7">
          <h1 :class="`kda-ts-${g_bLowestBr ? '24' : '36'}pt wt-extrabold title`">
            {{ strTitle }}
          </h1>
          <div
            :class="`${
              g_bLowestBr ? 'kda-ts-12pt' : g_bBaseBr ? 'kda-ts-18pt' : 'kda-ts-22pt'
            } nunito description`"
            v-html="htmlDescription"
          ></div>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
import baseBreakpoint from '../mixins/base-breakpoint';

export default {
  name: 'KDATeamStructureCard',
  mixins: [baseBreakpoint],
  props: {
    strTitle: {
      type: String,
      default: ''
    },
    htmlDescription: {
      type: String,
      default: ''
    },
    imgAvatar: {
      type: String,
      default: ''
    },
    numIndex: {
      type: Number,
      default: 0
    }
  },
  computed: {
    isEvenNumber() {
      return this.numIndex % 2 === 0;
    }
  }
};
</script>

<style scoped lang="scss">
.titleName {
  font-size: 36px;
}

.component-wrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 50px;
}

.row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.row-wrapper {
  display: flex;
  flex-direction: row;
}

.component-title-description .title .description {
  word-break: break;
}

.title {
  margin-bottom: 0.5em;
}

.component-image {
  background-color: transparent;
}

.avatar-image {
  width: 100%;
  height: auto;
  object-fit: contain;
  object-position: center;
  border: none;
  border-radius: 20px;
}
</style>
