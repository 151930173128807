<template>
  <base-img :src="require('@/assets/bg/bg-white-effect-1.png')">
    <base-section id="k-d-a-teams-structure">
      <div v-for="(item, index) in arrTeamStructure" :key="index" class="team-structure">
        <k-d-a-team-structure-card
          :str-title="item.strTitle"
          :html-description="item.strHtmlDescription"
          :img-avatar="item.strImgAvatar"
          :num-index="index"
        />
      </div>
    </base-section>
  </base-img>
</template>

<script>
import KDATeamStructureCard from '../../components/KDATeamStructureCard.vue';

export default {
  name: 'KDATeamsStructure',
  components: {
    KDATeamStructureCard
  },

  computed: {
    arrTeamStructure() {
      const results = [
        {
          strTitle: 'CEO Kenneth',
          strHtmlDescription: this.$vuetify.lang.t(`$vuetify.kda.teams.structure.desc1`),
          strImgAvatar: require(`@/assets/photos/teams-structure-1.png`)
        },
        {
          strTitle: 'COO Darren',
          strHtmlDescription: this.$vuetify.lang.t(`$vuetify.kda.teams.structure.desc2`),
          strImgAvatar: require(`@/assets/photos/teams-structure-2.png`)
        },
        {
          strTitle: 'CTO Reno',
          strHtmlDescription: this.$vuetify.lang.t(`$vuetify.kda.teams.structure.desc3`),
          strImgAvatar: require(`@/assets/photos/teams-structure-3.png`)
        }
      ];

      return results;
    }
  }
};
</script>
